const plusDescriptionEN = [
  "⭐️ Our bodies absorb too much bad food and air every day. Proper detoxification will help eliminate toxins, fats, retained water, and feces and enhance metabolic function.", " ",
  "10 Major Functions",
  "💗 Helps Whole Body Detox",
  "💗 Helps Relieve Constipation",
  "💗 Helps Eliminate Belly Fat",
  "💗 Helps Reduce Edema",
  "💗 Helps Improve Dull Skin",
  "💗 Helps Treat Acne",
  "💗 Helps Prevent Skin Aging",
  "💗 Helps Boosts Metabolism",
  "💗 Helps Replenish Vitamins",
  "💗 Helps Replenish Minerals", " ",
  "🌟 Recommend to consume before bedtime",
  "🌟 Mix with 200ml of room / warm temperature water",
  "🌟 Definitely will go to the toilet after 6-12 hours",
  "🌟 Not causing stomach cramps",
  "🌟 Recommend 4 boxes (2 months) for one treatment", " ",
  "🍀 100% Natural Ingredients",
  "⚖ 10g x 15 sachets", " ",
  "🍋 1 Box RM58",
  "🍋 2 Boxes RM108",
  "🍋 4 Boxes RM198",
  "🍋 6 Boxes RM288",
  "🍋 8 Boxes RM378", " ",
  "🚚 Nationwide Free Postage 📦", " ",
  "👉 KKM",
  "👉 HALAL",
  "👉 HACCP",
  "👉 MESTI",
  "👉 EUROPEAN QUALITY AWARD", " ",
  "100% Natural Ingredients from the United States, Italy, Belgium, India, and Singapore."
];


const plusDescriptionCN = [
  "⭐️ 我们人体每天都吸收太多不良的饮食及空气。适当的排毒会有助于我们排除体内的毒素、脂肪、滞留水份、宿便并且增强新陈代谢功能。", " ",
  "10 大主要功能",
  "💗 有助于全身排毒",
  "💗 有助于缓解便秘",
  "💗 有助于消除腹部脂肪",
  "💗 有助于减轻水肿",
  "💗 有助于改善暗淡肌肤",
  "💗 有助于治疗痤疮",
  "💗 有助于预防皮肤衰老",
  "💗 有助于促进新陈代谢",
  "💗 有助于补充维生素",
  "💗 有助于补充矿物质", " ",
  "🌟 建议睡前服用",
  "🌟 200ml 常温水 / 温水冲泡搅拌",
  "🌟 6-12 小时后肯定会上厕所",
  "🌟 不会导致肚子绞痛",
  "🌟 建议 4 盒一个疗程（2 个月）", " ",
  "🍀 100% 纯天然萃取的",
  "⚖️ 10 克 x 15 包", " ",
  "🍋 1 盒 RM58",
  "🍋 2 盒 RM108",
  "🍋 4 盒 RM198",
  "🍋 6 盒 RM288",
  "🍋 8 盒 RM378", " ",
  "🚚 全马一律包邮 📦", " ",
  "👉 KKM",
  "👉 HALAL",
  "👉 HACCP",
  "👉 MESTI",
  "👉 EUROPEAN QUALITY AWARD", " ",
  "100% 天然成分来自美国、意大利、比利时、印度和新加坡。",
];


const mealDescriptionEN = [
  '⭐️ Nutritional meal replacement provides the necessary nutrition for the entire day for the human body. It helps prevent the "3 major highs" (high blood sugar, high blood pressure, and high cholesterol), as well as diseases like obesity, constipation, and aging problems.', " ",
  "10 Major Functions",
  "💗 Helps Achieve a Sense of Fullness",
  "💗 Helps Suppress Appetite and Control Cravings",
  "💗 Helps Burn Fat and Calories",
  "💗 Helps Regulate Blood Sugar and Cholesterol",
  "💗 Helps Support Heart Health",
  "💗 Helps Reduce Signs of Aging and Fine Lines",
  "💗 Helps Alleviate Fatigue and Boosting Energy Levels",
  "💗 Helps Strengthen the Immune System",
  "💗 Helps Maintain Optimal Brain Function",
  "💗 Helps Enhance Overall Well-Being", " ",
  "🌟 Recommended Consumption Directions:",
  "🔑 Mix with 200ml of warm water",
  "🔑 Beneficial before exercise for muscle growth",
  "🔑 Recommend 2 months for one treatment", " ",
  "Jennieve Meal Replacement Programme",
  "💎 Level 1 🌟 (1 sachet a day): Expected weight loss of 1-3 kg in 2 months.",
  "💎 Level 2 🌟🌟 (2 sachets a day): Expected weight loss of 4-6 kg in 2 months.",
  "💎 Level 3 🌟🌟🌟 (3 sachets a day): Expected weight loss of 6 kg or more in 2 months.", " ",
  "💡 133 Kcal Low Calorie",
  "🌱 100% Natural Ingredients",
  "🥛 30g x 15 sachets",
  "⚖️ 8g Protein + 3g Fiber", " ",
  "💰 1 Box RM69",
  "💰 2 Boxes RM129",
  "💰 4 Boxes RM239",
  "💰 6 Boxes RM349",
  "💰 8 Boxes RM459", " ",
  "🚚 Nationwide Free Postage 📦", " ",
  "👉 KKM",
  "👉 HALAL",
  "👉 HACCP",
  "👉 MESTI",
  "👉 EUROPEAN QUALITY AWARD", " ",
  "100% Natural ingredients from Malaysia, Singapore, Germany, India, and the Netherlands."
];


const mealDescriptionCN = [
  "⭐️ 营养代餐，提供人体全天所需营养。有助于避免“三高（高血糖、高血压和高胆固醇），以及肥胖、便秘和衰老等疾病。", " ",
  "10 大主要功能",
  "💗 有助于产生饱腹感",
  "💗 有助于抑制和控制食欲",
  "💗 有助于燃烧脂肪和卡路里",
  "💗 有助于调节血糖和胆固醇",
  "💗 有助于支持心脏健康",
  "💗 有助于减缓衰老迹象和减少细纹",
  "💗 有助于缓解疲劳和提高能量水平",
  "💗 有助于强化免疫系统",
  "💗 有助于保持优化大脑功能",
  "💗 有助于提升整体健康", " ",
  "🌟 建议服用方法:",
  "🔑 200ml 温水冲泡搅拌",
  "🔑 运动前服用有助于肌肉生长",
  "🔑 建议 2 个月为一个疗程", " ",
  "Jennieve 代餐计划",
  "💎 第一级 🌟 (每天 1 包): 预期 2 个月减重 1-3 公斤。",
  "💎 第二级 🌟🌟 (每天 2 包): 预期 2 个月减重 4-6 公斤。",
  "💎 第三级 🌟🌟🌟 (每天 3 包): 预期 2 个月减重 6 公斤以上。", " ",
  "💡 133kcal 低热量",
  "🌱 100%天然成分",
  "🥛 30 克 x 15 包",
  "⚖️ 8 克蛋白质 + 3 克纤维", " ",
  "💰 1 盒 RM69",
  "💰 2 盒 RM129",
  "💰 4 盒 RM239",
  "💰 6 盒 RM349",
  "💰 8 盒 RM459", " ",
  "🚚 全马一律包邮 📦", " ",
  "👉 KKM",
  "👉 HALAL",
  "👉 HACCP",
  "👉 MESTI",
  "👉 EUROPEAN QUALITY AWARD", " ",
  "100% 天然成分来自马来西亚、新加坡、德国、印度和荷兰。",
];


const careDescriptionEN = [
  "⭐️ Designed by our local Malaysia nutritionist with over 20 years of experience, to help females regulate their menstruation and hormones.", " ",
  "10 Major Functions",
  "😍 Helps Regulate Menstrual Period",
  "😍 Helps Relieve Menstrual Pain",
  "😍 Helps Improve Blood and Qi Cycle",
  "😍 Helps Improve Blood Deficiency",
  "😍 Helps Cure Leucorrhea Problem",
  "😍 Helps Regulate Secretion",
  "😍 Helps Cure Vaginal Inflammation",
  "😍 Helps Improve Immune System",
  "😍 Helps Balance Hormones",
  "😍 Helps Relieve Menopausal Symptoms", " ",
  "🌟 For mild menstrual discomfort, mild dizziness, mild abdominal pain, mild anemia, leucorrhea with a peculiar odor, and insomnia problems.",
  "    (Recommend to take 1 sachet daily ✅ Significant improvements after 2-4 boxes).", " ",
  "🌟 For severe discomfort symptoms before/during menstruation, severe hormonal imbalance, severe endocrine imbalance, long-term menstruation irregularity and unpunctuality, severe anemia, low menstrual blood, severe menopausal discomfort, vaginitis, and urethritis.",
  "    (Recommend to take 2 sachets daily ✅ Accelerate conditioning 🚀🚀🚀 Significant improvements after 4-8 boxes).", " ",
  "⚠️Stop taking it during menstruation and resume taking it the next day after menstruation is over⚠️", " ",
  "🌟 Drink a sachet before bedtime",
  "🌟 Mix with 100-150ml of Room / Warm Temperature Water", " ",
  "🍀 100% Natural Ingredients",
  "⚖️ 10g x 15 Sachets", " ",
  "🍷 1 Box RM88",
  "🍷 2 Boxes RM168",
  "🍷 4 Boxes RM328",
  "🍷 6 Boxes RM468",
  "🍷 8 Boxes RM598  ", " ",
  "🚚 Nationwide Free Postage 📦", " ",
  "👉 KKM",
  "👉 HALAL",
  "👉 HACCP",
  "👉 MESTI",
  "👉 EUROPEAN QUALITY AWARD", " ",
  "🍀 100% Natural ingredients: Red Dates, Dong Quai, Kacip Fatimah, Vitamin E, Longan, and Manjakani.",
];


const careDescriptionCN = [
"⭐️ 拥有 20 多年经验的马来西亚营养师研发的，帮助女性调节月经和荷尔蒙。"," ",
"10 大主要功能",
"😍 有助于调节月经周期",
"😍 有助于缓解经痛",
"😍 有助于改善血液和气循环",
"😍 有助于改善血虚问题",
"😍 有助于治疗白带问题",
"😍 有助于调节分泌",
"😍 有助于治疗阴道炎",
"😍 有助于提高免疫系统",
"😍 有助于平衡激素",
"😍 有助于缓解更年期症状"," ",
"🌟 普通经期不适、轻微头晕、轻微腹痛、轻微贫血、白带异味、失眠问题",
"    （建议每天服用 1 包 ✅  2-4 盒可以看到不错效果了）"," ",
"🌟 严重经期前/经期中不适症状、严重荷尔蒙失调、内分泌失调、长期没来月经、经期不准时、严重贫血、月经排血量很少、严重更年期不适、阴道炎、尿道炎",
"    （建议每天服用 2 包 ✅ 加速调理 🚀🚀🚀 4-8 盒可以看到明显的改善效果了）"," ",
"⚠️月经期间暂停服用，来完月经干净后隔天才继续服用⚠️"," ",
"🌟 建议在晚上睡前服用",
"🌟 100-150ml 常温水 / 温水冲泡搅拌"," ",
"🍀 100% 纯天然植物萃取",
"⚖️ 10 克 x 15 包"," ",
"🍷 1 盒 RM88",
"🍷 2 盒 RM168",
"🍷 4 盒 RM328",
"🍷 6 盒 RM468",
"🍷 8 盒 RM598"," ",
"🚚 全马一律免邮 📦"," ",
"👉 KKM",
"👉 HALAL",
"👉 HACCP",
"👉 MESTI",
"👉 EUROPEAN QUALITY AWARD"," ",
"🍀 100% 纯天然成分：混合莓果、当归、卡棋花蒂玛、维生素 E、龙眼、蔓加卡丽。",
];


const collDescriptionEN = [
  "⭐️ Collagen peptide whitening and the hydrating product help you regain the angelic white and tender skin you had N years ago.", " ",
  "10 Major Functions",
  "⭕️ Helps Highly Hydrate and Moisturize",
  "⭕️ Helps Activate Skin Cells",
  "⭕️ Helps Increase Skin Elasticity",
  "⭕️ Helps Detoxify Skin and Whiten",
  "⭕️ Helps Speed up Skin's Metabolism",
  "⭕️ Helps Prevent UV Damage",
  "⭕️ Helps Accelerate Skin Repair",
  "⭕️ Helps Tighten and Prevent Aging Skin",
  "⭕️ Helps Reduce Wrinkles and Spots",
  "⭕️ Helps Reduce Acne Problems", " ",
  "🌟 Drink a sachet before breakfast in the morning",
  "🌟 Mix with 100-150ml of room / warm temperature water",
  "🌟 Recommend 4 boxes (2 months) for one treatment", " ",
  "🍀 100% Natural Ingredients",
  "⚖️ 10g x 15 Sachets"," ",
  "▶️ 1 Box RM59",
  "▶️ 2 Boxes RM109",
  "▶️ 4 Boxes RM199",
  "▶️ 6 Boxes RM289",
  "▶️ 8 Boxes RM379", " ",
  "🚚 Nationwide Free Postage 📦", " ",
  "👉 KKM",
  "👉 HALAL",
  "👉 HACCP",
  "👉 MESTI",
  "👉 EUROPEAN QUALITY AWARD", " ",
  "100% Natural ingredients from Italy, Germany, China, Japan, and Malaysia.",
];


const collDescriptionCN = [
  "⭐️ 胶原蛋白肽美白补水产品，帮你找回 N 年前天使般白白嫩嫩的肌肤。", " ",
  "10 大主要功能",
  "⭕️ 有助于高度滋润和保湿",
  "⭕️ 有助于激活皮肤细胞",
  "⭕️ 有助于增加皮肤弹性",
  "⭕️ 有助于排毒美白皮肤",
  "⭕️ 有助于加快皮肤新陈代谢",
  "⭕️ 有助于预防紫外线伤害",
  "⭕️ 有助于加速皮肤修复",
  "⭕️ 有助于紧致肌肤和预防衰老",
  "⭕️ 有助于减少皱纹和斑点",
  "⭕️ 有助于减少痘痘问题", " ",
  "🌟 建议早上早餐前空腹服用 ",
  "🌟 100-150ml 常温水 / 温水冲泡搅拌",
  "🌟 建议 4 盒一个疗程（2 个月）", " ",
  "🍀 100% 纯天然萃取",
  "⚖️ 10 克 x 15 包", " ",
  "▶️ 1 盒 RM59",
  "▶️ 2 盒 RM109",
  "▶️ 4 盒 RM199",
  "▶️ 6 盒 RM289",
  "▶️ 8 盒 RM379", " ",
  "🚚 全马一律免邮 📦", " ",
  "👉 KKM",
  "👉 HALAL",
  "👉 HACCP",
  "👉 MESTI",
  "👉 EUROPEAN QUALITY AWARD", " ",
  "100% 天然成分来自意大利、德国、中国、日本和马来西亚。",
];

export const data = {
  plus: { CN: plusDescriptionCN, EN: plusDescriptionEN },
  meal: { CN: mealDescriptionCN, EN: mealDescriptionEN },
  care: { CN: careDescriptionCN, EN: careDescriptionEN },
  coll: { CN: collDescriptionCN, EN: collDescriptionEN },
};
